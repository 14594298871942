@mixin alert-style($background-color, $color){
    background-color: $background-color;
    color: $color;
    .close{
        color: $color;
        opacity: 0.7;
        &:hover{
            opacity: 1;
        }
    }
}
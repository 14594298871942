@import "paper-kit";

// Custom Fonts
// -----------------------------------------

@font-face {
	font-family: 'bignoodletitling';
	src: url('../fonts/bignoodletitling.eot');
	src: local('bignoodletitling'),
  url('../fonts/bignoodletitling.woff') format('woff'),
  url('../fonts/bignoodletitling.ttf') format('truetype');
}

@font-face {
	font-family: 'bignoodletitlingoblique';
	src: url('../fonts/bignoodletitlingoblique.eot');
	src: local('bignoodletitlingoblique'),
  url('../fonts/bignoodletitlingoblique.woff') format('woff'),
  url('../fonts/bignoodletitlingoblique.ttf') format('truetype');
}

// Helpers
// -----------------------------------------

.mb_custom_font {
  font-family: 'bignoodletitling', sans-serif !important;
}

.mb_custom_font_oblique {
  font-family: 'bignoodletitlingoblique', sans-serif !important;
}

.mb_text_right {
	text-align: right;
}

.mb_weight_normal {
	font-weight: normal;
}

// Common
// -----------------------------------------

html, body, .card, .title, .title a, .card-title, .card-title a, .info-title, .info-title a, .footer-brand, .footer-brand a, .footer-big h5, .footer-big h5 a, .footer-big h4, .footer-big h4 a, .media .media-heading, .media .media-heading a {
	color: $white-color !important;
}

.section {
	background-color: $dark-blue-color;
}

h2, .h2 {
	font-size: 3.8em;
}

h3, .h3 {
  font-size: 3em;
}

// Header
// -----------------------------------------

.navbar {
	background: $dark-blue-color;
	border-top: solid 5px $primary-color;
	// border-bottom: solid 1px $other-medium-gray;

	&.mb_theater_mode {
		display: none;
	}
}

.navbar-transparent .navbar-nav .nav-item .nav-link:hover {
	color: $primary-color;
}

.navbar-transparent {
  background: rgba(21, 25, 37, 0.75) !important;
	border-bottom: none;
	padding-top: 20px !important;
}

.btn-outline-default {
	font-size: 20px !important;
  font-weight: normal !important;
}

.btn-outline-default:hover {
	background-color:  $primary-color;
	border-color: $primary-color;
	color: $white-color !important;
}

.navbar .btn {
	font-size: 20px !important;
  font-weight: normal !important;
}

.navbar .navbar-nav .nav-item .nav-link {
  line-height: 1;
  font-size: 20px;
  font-weight: normal;
	padding: 10px;

	i.fa {
		margin-right: 3px;
	}
}

// Hero
// -----------------------------------------

.page-header {
	display:flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  align-items: center;
	background-position: center center;
	background-size: cover;
	height: 70vh;
	min-height: 850px;
	max-height: 999px;
	overflow: hidden;
	position: relative;
	width: 100%;
	z-index: 2;

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		background: rgba(21, 25, 37, 0.8);
		// background: linear-gradient(45deg,
		// rgba(21, 25, 37, 0.8) 0%, rgba(21, 25, 37, 0.9) 100%);
		// background: -moz-linear-gradient(45deg,
		// rgba(21, 25, 37, 0.8) 0%, rgba(21, 25, 37, 0.9) 100%);
		// background: -webkit-linear-gradient(45deg,
		// rgba(21, 25, 37, 0.8) 0%, rgba(21, 25, 37, 0.9) 100%);
	}

	.mb_hero {
		background: #17141f;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		overflow: hidden;
		flex-grow: 0;
		flex-shrink: 0;
		margin-top: 65px;
		-webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);
		-moz-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);
		box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);

		&.mb_theater_mode {
			-webkit-border-radius: 0;
			-moz-border-radius: 0;
			border-radius: 0;
			margin: 0;
			display: block;
			position: fixed;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			z-index: 1000;
			width: 100%;
			height: 100vh;

			.iframe_twitch_video, .iframe_twitch_chat {
				height: calc(100vh - 70px);
			}
		}

		h1 {
			display: inline;
		}

		h3 {
			color: $white-color !important;
			display: inline-block;
			font-size: 24px;
			margin: 0 0 0 15px;

			svg {
				margin-right: 5px;
			}
		}

		.mb_stream_title {
			line-height: 1.4;
			font-size: 30px;
			font-weight: 300;
		}

		.mb_stream_menu {
			padding: 15px 0 0 15px;
			height: 70px;
		}

		.mb_btn_expand {
			font-size: 15px;
    	padding: 2px 8px;
		}

		.mb_btn_twitch {
			margin: 5px;
		}

		.mb_btn_twitch_color {
			background-color: $font-color !important;
			border-color: $font-color !important;
		}

		.iframe_twitch_video{
			margin: 0;
			padding: 0;
			height: 430px;
		}

		.iframe_twitch_chat {
			margin: 0;
			padding: 0;
			height: 500px;
		}

		.iframe_twitch_video iframe, .iframe_twitch_chat iframe {
			width: 100%;
			height: 100%;
		}
	}

}

// Section
// -----------------------------------------

.social-line .title {
	font-size: 3em;
	line-height: 1.75;
}

.mb_btn_social {
	font-size: 30px;
	font-weight: 400;

	i {
		margin-right: 15px;
	}
}

.social-line-black {
	background-color: $font-color !important;
}

#about {
	background-position: center center;
	background-size: cover;
	overflow: hidden;
	z-index: 1;

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		background: rgba(21, 25, 37, 0.9);
	}

	.container {
		z-index: 1;
	}
}

#my-tab-content {

	#carousel {
		width: 100%;
	  margin: 0;

		.carousel {
			border-radius: 3px !important;
			background-color: $dark-blue-color;
			-webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);
			-moz-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);
			box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);
		}

		.carousel-control, .carousel-indicators .active {
			background-color:  $primary-color !important;
		}

		.carousel-item img {
	    border-radius: 3px !important;
		}
	}
}

.card-title {
	color: $font-color !important;
	font-size: 1.8em;
}

.mb_medias {
	margin-top: 60px;
}

.nav-tabs .nav-item .nav-link {
	font-size: 24px;
}

.nav-tabs .nav-item .nav-link.active {
	color: $white-color;
}

.nav-tabs .nav-item .nav-link.active:after, .nav-tabs .nav-item .nav-link.active:hover:after, .nav-tabs .nav-item .nav-link.active:focus:after {
	bottom: 6px;
}

.table > thead > tr > th {
	color: $font-color !important;
}

#coaching {

	&.social-line {
		padding: 90px 0 !important;
	}

	.btn-primary {
		margin-top: 15px;
    background-color: $white-color !important;
    border-color: $white-color !important;
    color: $primary-color !important;
	}

	h2 {
		margin-bottom: 20px;
	}

	.card-title {
		margin: 0 0 30px 0;
	}
}

.mb_google_calendar {
	margin-top: 30px;
	padding: 25px;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
}

#vod {

	h2 {
		display: inline-block;
		margin-right: 20px;
		margin-bottom: 30px;

		a {
			margin-left: 20px;
		}

		i.fa-chevron-right {
			display: inline-block;
			font-size: 16px;
		}
	}
}

div.yottie-widget-feed-section-loader {
	background: transparent !important;
}

.yottie-widget-feed-section-search {
	text-align: center !important;
}

.yottie-widget-feed-section-search-form {
	display: inline-block !important;
	float: none !important;
}

.yottie-widget-feed-section-search-form-button {
	margin: 10px 10px 0 0 !important;
	padding: 0 !important;
}

.yottie, .yottie-widget-feed-section-search-form-input {
	font-family: $font-family-sans-serif !important;
	font-weight: 500 !important;
}

a.yottie-widget-video-info-title {
	font-family: 'bignoodletitlingoblique', sans-serif !important;
	font-size: 20px;
}

.yottie-popup .yottie-popup-video-title {
	font-family: 'bignoodletitlingoblique', sans-serif !important;
	line-height: 40px !important;
	font-size: 40px !important;
}

div.yottie-widget-video-info-caption {
	font-size: 14px;
}

.yottie-widget-feed-section-search-form-input {
	-webkit-border-radius: 5px !important;
	-moz-border-radius: 5px !important;
	border-radius: 5px !important;
  border: 1px solid $white-color !important;
  padding: 8px 16px;
  width: 100%;
  color: $white-color !important;
}

#config {
	background-position: center center;
	background-size: cover;
	overflow: hidden;
	z-index: 1;

	&::after {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		background: rgba(21, 25, 37, 0.9);
	}

	a.mb_logo_config {
		padding-left: 5px;

		img {
			vertical-align: baseline !important;
		}
	}

	.container {
		z-index: 1;
	}
}

#partners {
	padding-bottom: 50px;

	.mb_link_partner {
		padding: 25px 18px;
	}

	.mb_logos_container {
		display:flex;
    flex-wrap: wrap;
		justify-content: center;
    align-content: center;
    align-items: center;
	}
}


// Footer
// -----------------------------------------

.footer {
	padding: 60px 0;

	a:hover {
		color: $primary-color !important;
	}

	.copyright, .copyright a {
		font-weight: 700;
	}

	.links ul a:not(.btn) {
		color: $white-color;
		font-weight: 700;
	}

	.social-area .btn {
    margin-left: 10px;
    float: right;
	}
}

@media screen and (max-width: 992px) {

}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 320px) {

}


// Programmation
// -----------------------------------------

#programmation {
	display: block;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-position: center center;
	background-size: cover;
	// overflow: hidden;
	z-index: 9998;
	width: 100%;
	height: 100%;
	-webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);
	-moz-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);
	box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);
	transition: all 0.5s ease;

	&.section {
		padding: 0;
	}

	&.hidden_prog {
		left: -5000px;
	}

	.mb_btn_prog_off {
		display: block;
		position: absolute;
		top: 15px;
		right: 30px;
	}

	.container {
		z-index: 1;
	}

	.mb_prog_title {
		width: 100%;
		height: auto;

		h2 {
			margin-top: 50px;
		}
	}
}

// Legals
// -----------------------------------------

#legals {
	display: block;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-position: center center;
	background-size: cover;
	// overflow: hidden;
	z-index: 9998;
	width: 100%;
	height: 100%;
	-webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);
	-moz-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);
	box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.6);
	transition: all 0.5s ease;

	&.section {
		padding: 0;
	}

	&.hidden_legals {
		left: -5000px;
	}

	.mb_btn_legals_off {
		display: block;
		position: absolute;
		top: 15px;
		right: 30px;
	}

	.container {
		z-index: 1;
	}

	.mb_legals_title {
		width: 100%;
		height: auto;

		h2 {
			margin-top: 50px;
			margin-bottom: 30px;
		}
	}
}



// Colors
// -----------------------------------------

.mb_color_primary {
	color: $primary-color !important;
}

.mb_color_white {
	color: $white-color !important;
}

.mb_color_dark_blue {
	color: $dark-blue-color !important;
}

.mb_bkgcolor_white {
	background-color: $white-color !important;
}

.mb_bkgcolor_color_primary {
	background-color: $primary-color !important;
}

.mb_bkgcolor_dark_blue {
	background-color: $dark-blue-color !important;
}

.mb_bkgcolor_light_grey {
	background-color: $font-color !important;
}

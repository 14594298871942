/* ============================================================
 * bootstrapSwitch v1.3 by Larentis Mattia @spiritualGuru
 * http://www.larentis.eu/switch/
 * ============================================================
 * Licensed under the Apache License, Version 2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 * ============================================================ */
.has-switch {
    border-radius: 30px;
    cursor: pointer;
    display: inline-block;
    line-height: 1.72222;
    overflow: hidden;
    position: relative;
    text-align: left;
    width: 61px;

   -webkit-user-select: none;
   -moz-user-select: none;
   -ms-user-select: none;
   -o-user-select: none;
   user-select: none;

    /*   this code is for fixing safari bug with hidden overflow for border-radius   */
    -webkit-mask: url('../img/mask.png') 0 0 no-repeat;
    -webkit-mask-size: 61px 28px;
    mask: url('../img/mask.png') 0 0 no-repeat;
    mask-size: 61px 28px;
}
.has-switch.deactivate {
  opacity: 0.5;
  filter: alpha(opacity=50);
  cursor: default !important;
}
.has-switch.deactivate label,
.has-switch.deactivate span {
  cursor: default !important;
}
.has-switch > div {
   position: relative;
   top: 0;
   width: 100px;
}
.has-switch > div.switch-animate {
  -webkit-transition: left 0.25s ease-out;
  transition: left 0.25s ease-out;
}
.has-switch > div.switch-off {
  left: -35px;
}

.has-switch > div.switch-on {
  left: 0;
}
.has-switch > div label {
  background-color: #FFFFFF;

  box-shadow: 0 1px 1px #FFFFFF inset, 0 1px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.has-switch input[type=checkbox] {
  display: none;
}
.has-switch span {
    cursor: pointer;
    float: left;
    font-size: 11px;
    font-weight: 500;
    height: 26px;
    line-height: 15px;
    margin: 0;
    padding-bottom: 6px;
    padding-top: 5px;
    position: relative;
    text-align: center;
    text-indent: -10px;
    width: 50%;
    z-index: 1;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.has-switch span.switch-left {
    background-color: $font-color;
    border-left: 1px solid rgba(0, 0, 0, 0);
    border-radius: 30px 0 0 30px;
    color: #FFFFFF;
}
.has-switch .switch-off span.switch-left{
    background-color: $medium-gray;
}
.has-switch span.switch-right {
  background-color: $font-color;
  border-radius: 0 30px 30px 0;
  color: #ffffff;
  text-indent: 1px;
}
.has-switch .switch-off span.switch-right{
    background-color: $medium-gray;
}

.has-switch label {
    border-radius: 12px;
    float: left;
    height: 22px;
    margin: 2px -13px;
    padding: 0;
    position: relative;
    transition: all 0.25s ease-out 0s;
    vertical-align: middle;
    width: 22px;
    z-index: 100;
  -webkit-transition: 0.25s ease-out;
  transition: 0.25s ease-out;
}
.has-switch .switch-on .fa-check:before{
    margin-left: 10px;
}
.has-switch:hover .switch-on label{
    margin: 2px -17px;
    width: 26px;
}
.has-switch:hover .switch-off label{
    margin: 2px -13px;
    width: 26px;
}
input.ct-primary + span.switch-left,
input.ct-primary + span + label + span.switch-right{
    background-color:  $primary-color;
}
input.ct-info + span.switch-left,
input.ct-info + span + label + span.switch-right{
    background-color:  $info-color;
}
input.ct-success + span.switch-left,
input.ct-success + span + label + span.switch-right{
    background-color:  $success-color;
}
input.ct-warning + span.switch-left,
input.ct-warning + span + label + span.switch-right{
    background-color:  $warning-color;
}
input.ct-danger + span.switch-left,
input.ct-danger + span + label + span.switch-right{
    background-color:  $danger-color;
}
